import { UUID } from '@/api/common';
import { AssetsWithKpis, fetchKpisForMultipleAssets } from '@/api/kpis';
import { Value } from '@/api/value';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { MaybeRef } from '@/utils/ref';
import { useQuery, UseQueryReturnType } from '@tanstack/vue-query';
import { computed, unref } from 'vue';
import { GenericQueryOptions } from './common';

const queryKeys = {
  multiAssetKpis: (
    assetIds: UUID[] | undefined,
    propertyCodes: string[],
    context?: ActiveContext
  ) =>
    ({
      query: 'multi-asset-kpis',
      assetIds: assetIds,
      propertyCodes: propertyCodes,
      context,
    } as const),
};

/**
 * Fetch multiple properties for multiple assets.
 *
 * If assetIDs is undefined, the query will be disabled.
 */
export function useMultiAssetKpisQuery<
  T extends object = Record<string, Value<unknown>>
>(
  assetIds: MaybeRef<UUID[] | undefined>,
  propertyCodes: MaybeRef<string[]>,
  options?: GenericQueryOptions<
    AssetsWithKpis<T>,
    (typeof queryKeys)['multiAssetKpis']
  >
): UseQueryReturnType<AssetsWithKpis<T>, Error> {
  const context = useActiveContext();
  return useQuery({
    staleTime: 10 * 1000,
    enabled: computed(() => unref(assetIds) !== undefined),
    ...options,
    queryKey: [
      computed(() =>
        queryKeys.multiAssetKpis(
          unref(assetIds),
          unref(propertyCodes),
          unref(context)
        )
      ),
    ],
    queryFn: ({ queryKey: [{ assetIds, propertyCodes, context }] }) =>
      fetchKpisForMultipleAssets(
        assetIds!,
        propertyCodes,
        context as ActiveContext
      ),
  });
}
