<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { editRegisterAsset } from '@/api/assetsMgmt';
import { ErrorType } from '@/api/types';
import NavPage from '@/components/nav/NavPage.vue';
import { PageModule } from '@/store/modules/page';
import { customFailedMessage, promptSuccessBox } from '@/utils/prompt';

import RegisterAssetForm from '../components/RegisterAssetForm.vue';
import { formatDateForServer } from '../helper';

@Component({
  name: 'EditRegisterAsset',
  components: {
    NavPage,
    RegisterAssetForm,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetId!: string;

  title: string = '';
  validateFieldInputs: boolean = false;
  isRegisterFormValid: boolean = false;
  registerAssetForm = {
    assetType: '',
    productModelId: '',
    modelNumber: '',
    serviceRegion: '',
    hyvaRegion: '',
    serialNumber: '',
    manufactureDate: '',
    timeZone: '',
    controllerId: '',
    vinNumber: '',
    preliminaryAssetId: '',
  };
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  created() {}

  getTitleInfo(titleInfo: string) {
    PageModule.setTitle(titleInfo);
  }

  /** Trigger on Save btn event */
  validateForm() {
    this.validateFieldInputs = !this.validateFieldInputs;
  }

  /** Trigger when validation are done from register form element */
  checkIsValidFormInput(isValid: boolean, productModelId?: string) {
    this.isRegisterFormValid = isValid;

    if (!this.isRegisterFormValid) return;

    this.editRegsterAssetForm(productModelId);
  }

  editRegsterAssetForm(productModelId?: string) {
    const editRegisterForm = this.$refs.editRegisterForm as any;
    (editRegisterForm.$refs.registerAssetForm as any).validate((valid: any) => {
      if (valid) {
        let submitData: any = {
          productModelId: productModelId || this.registerAssetForm.modelNumber,
          serviceRegion: this.registerAssetForm.serviceRegion,
          manufactureDate: formatDateForServer(
            this.registerAssetForm.manufactureDate
          ),
          timezone: this.registerAssetForm.timeZone,
          controllerId: this.registerAssetForm.controllerId,
          preliminaryAssetId:
            this.registerAssetForm.preliminaryAssetId === ''
              ? undefined
              : this.registerAssetForm.preliminaryAssetId,
          vinNumber:
            this.registerAssetForm.vinNumber === ''
              ? undefined
              : this.registerAssetForm.vinNumber,
        };
        editRegisterAsset(this.assetId, submitData).then((res) => {
          if (res.code === 200) {
            promptSuccessBox(this.$t('common.edit') as string);
            this.errorInfos = [];
            this.$router.push('/asset-mgmt/index?activeName=registerAssets');
          } else if (res.code === 400) {
            this.errorInfos = [...res.data.errors[0]];
            customFailedMessage(this.errorInfos[0].message);
          } else if (res.code === 500) {
            customFailedMessage(
              this.$t('common.errorWithFetchingData') as string
            );
          }
        });
      }
    });
  }
}
</script>

<template>
  <NavPage
    :arrowBack="false"
    :visible="true"
    @arrow-click="$router.push('/asset-mgmt/index?activeName=registerAssets')"
  >
    <template #button>
      <el-button type="plain" @click="validateForm">
        <svg-icon class="save-icon" name="save" />
        {{ $t('assetMgmt.save') }}
      </el-button>
    </template>
    <div id="edit_register_asset_box" style="padding: 0 40px">
      <RegisterAssetForm
        ref="editRegisterForm"
        :editAssetId="assetId"
        :registerAssetForm="registerAssetForm"
        :validateFieldInputs="validateFieldInputs"
        @is-valid-event="checkIsValidFormInput"
        @send-title="getTitleInfo"
      />
    </div>
  </NavPage>
</template>

<style lang="scss" scoped></style>
