import { render, staticRenderFns } from "./CardButton.vue?vue&type=template&id=a459fcc6&scoped=true"
import script from "./CardButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./CardButton.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CardButton.vue?vue&type=style&index=0&id=a459fcc6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a459fcc6",
  null
  
)

export default component.exports