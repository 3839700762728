<script lang="ts">
import { CustomizedKpiLimit } from '@/api/alarmConfig';
import { KpiDataField } from '@/api/kpis';
import LineChart from '@/components/kpiCharts/LineChart.vue';
import {
  booleanStateToTranslatedName,
  flagToTranslatedName,
  KpiChartDataItem,
  KpiLimitAndWarningInfo,
  oilLevelValueToTranslatedName,
  realTimeKPIChartData,
  realTimeKpiLimitAndWarningInfo,
  valueLimitCheckToColor,
} from '@/utils/kpidata';
import { FILENAME_TIMESTAMP_FORMAT } from '@/utils/time';
import {
  MetricConfig,
  RealTimeKpiCodes,
  REAL_TIME_KPIS,
} from '@/utils/workData/lookuptable';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import moment from 'moment';
import Papa from 'papaparse';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface AllRealTimeKPIData {
  payload: KpiChartDataItem | undefined;
  oilTemperature: KpiChartDataItem | undefined;
  hydraulicPressure: KpiChartDataItem | undefined;
  filling: KpiChartDataItem | undefined;
  oilLevel: KpiChartDataItem | undefined;
  oilFilterClogged: KpiChartDataItem | undefined;
}

@Component({
  name: 'LiveMonitoringLineCharts',
  components: {
    LineChart,
  },
})
export default class extends Vue {
  @Prop({ required: true }) companyAssetId!: string;
  @Prop({ required: true }) allChartData!: KpiDataField[];
  @Prop({ default: true }) isRendering!: boolean;
  @Prop({ default: 5 }) refreshSeconds!: number;
  @Prop({ required: true }) limitAlarmInfo!: CustomizedKpiLimit[];

  $refs!: {
    payloadKpi: LineChart;
    oilTemperatureKpi: LineChart;
    hydraulicPressureKpi: LineChart;
    fillingKpi: LineChart;
    oilLevelKpi: LineChart;
    oilFilterKpi: LineChart;
  };

  kpiKeys: Array<keyof typeof this.$refs> = [
    'payloadKpi',
    'oilTemperatureKpi',
    'hydraulicPressureKpi',
    'fillingKpi',
    'oilLevelKpi',
    'oilFilterKpi',
  ];

  //current payload kpi
  payloadUnit: string = 'metricUnits.ton';
  payloadChartData: KpiChartDataItem[] = [];
  payloadLimitWarningRange: KpiLimitAndWarningInfo =
    {} as KpiLimitAndWarningInfo;
  // oil Temperature kpi
  oilTemperatureUnit: string = 'metricUnits.celsius';
  oilTemperatureChartData: KpiChartDataItem[] = [];
  oilTemperatureLimitWarningRange: KpiLimitAndWarningInfo =
    {} as KpiLimitAndWarningInfo;
  //hydraulic pressure
  hydraulicPressureUnit: string = 'metricUnits.bar';
  hydraulicPressureChartData: KpiChartDataItem[] = [];
  hydraulicPressureLimitWarningRange: KpiLimitAndWarningInfo =
    {} as KpiLimitAndWarningInfo;
  //filling percentage
  fillingUnit: string = 'metricUnits.percentage';
  fillingChartData: KpiChartDataItem[] = [];
  fillingLimitWarningRange: KpiLimitAndWarningInfo =
    {} as KpiLimitAndWarningInfo;
  //oil level
  oilLevelChartData: KpiChartDataItem[] = [];
  oilLevelLimitWarningRange: KpiLimitAndWarningInfo = {
    lowLimit: 0.5,
  } as KpiLimitAndWarningInfo;

  oilFilterCloggedChartData: KpiChartDataItem[] = [];
  oilFilterWarningRange: KpiLimitAndWarningInfo = {} as KpiLimitAndWarningInfo;

  allRealTimeKPILastestData: AllRealTimeKPIData = {} as AllRealTimeKPIData; //all the latest data in 5 kpis
  initDataLength: number = 100; //the init data length
  initNumberCount: number = 0; //the init number count

  realTimeKpiCodes = RealTimeKpiCodes;
  applicableRealTimeKpis: MetricConfig[] = [];

  created() {
    this.fillData(this.payloadChartData);
    this.fillData(this.oilTemperatureChartData);
    this.fillData(this.hydraulicPressureChartData);
    this.fillData(this.fillingChartData);
    this.fillData(this.oilLevelChartData);
    this.fillData(this.oilFilterCloggedChartData);

    const assetTypeCode = ROUTE_ASSET_MAP.get(
      this.$route.name ?? ''
    )?.assetTypeCode;
    if (!assetTypeCode) {
      throw new Error('Unknown asset type');
    }

    const kpis = REAL_TIME_KPIS[assetTypeCode];
    if (!kpis) {
      throw new Error(
        `Unsupported real time metrics for asset type ${assetTypeCode}`
      );
    }

    this.applicableRealTimeKpis = kpis;
  }

  mounted() {
    this.updateAlarmInfos(this.limitAlarmInfo);
    this.collateChartData(this.allChartData);
  }

  //collate the data
  @Watch('allChartData', { deep: true })
  collateChartData(handleData: KpiDataField[]) {
    if (handleData.length > 0) {
      let timeStamp: string = moment().format('YYYY-MM-DD hh:mm:ss');
      this.applicableRealTimeKpis.forEach((config) => {
        let chartDataItem = realTimeKPIChartData(handleData, config, timeStamp);
        switch (config.code) {
          case RealTimeKpiCodes.CurrentPayload:
            this.addItemAndRemoveOverflow(this.payloadChartData, chartDataItem);
            break;
          case RealTimeKpiCodes.OilTemperature:
            this.addItemAndRemoveOverflow(
              this.oilTemperatureChartData,
              chartDataItem
            );
            break;
          case RealTimeKpiCodes.HydraulicPressure:
            this.addItemAndRemoveOverflow(
              this.hydraulicPressureChartData,
              chartDataItem
            );
            break;
          case RealTimeKpiCodes.CompactorFillingPercentage:
            this.addItemAndRemoveOverflow(this.fillingChartData, chartDataItem);
            break;
          case RealTimeKpiCodes.OilLevelLow:
            this.addItemAndRemoveOverflow(
              this.oilLevelChartData,
              chartDataItem
            );
            break;
          case RealTimeKpiCodes.OilFilterClogged:
            this.addItemAndRemoveOverflow(
              this.oilFilterCloggedChartData,
              chartDataItem
            );
            break;

          default:
            break;
        }
      });
      if (this.initNumberCount < this.initDataLength) this.initNumberCount++;
    }
    if (this.isRendering) {
      this.refreshLastestData();
    }
  }

  @Watch('isRendering')
  handleStopRender(newData: boolean, oldData: boolean) {
    if (!newData && oldData) {
      this.refreshLastestData();
    }
    return;
  }

  @Watch('limitAlarmInfo')
  updateAlarmInfos(newInfos: CustomizedKpiLimit[]) {
    this.payloadLimitWarningRange = realTimeKpiLimitAndWarningInfo(
      newInfos,
      'Current Payload'
    );
    this.oilTemperatureLimitWarningRange = realTimeKpiLimitAndWarningInfo(
      newInfos,
      'Oil Temperature'
    );
    this.fillingLimitWarningRange = realTimeKpiLimitAndWarningInfo(
      newInfos,
      'Compactor Filling Percentage'
    );
    this.hydraulicPressureLimitWarningRange = realTimeKpiLimitAndWarningInfo(
      newInfos,
      'Hydraulic Pressure'
    );
  }

  //the maximum number is one hour data
  get maximumLength() {
    let maximumNumber: number = 720;
    maximumNumber = parseInt((3600 / this.refreshSeconds).toString());
    return maximumNumber;
  }

  get realTimeKPIValues() {
    return {
      payload: this.allRealTimeKPILastestData.payload?.[1] ?? '',
      oilTemperature: this.allRealTimeKPILastestData.oilTemperature?.[1] ?? '',
      hydraulicPressure:
        this.allRealTimeKPILastestData.hydraulicPressure?.[1] ?? '',
      filling: this.allRealTimeKPILastestData.filling?.[1] ?? '',
      oilLevel: oilLevelValueToTranslatedName(
        Number(this.allRealTimeKPILastestData.oilLevel?.[1])
      ),
      oilFilter: flagToTranslatedName(
        Boolean(this.allRealTimeKPILastestData.oilFilterClogged?.[1])
      ),
    };
  }

  realTimeKPIColor(value: KpiChartDataItem | undefined): string {
    return valueLimitCheckToColor(value?.[2]);
  }

  fillData(chartData: KpiChartDataItem[]) {
    for (let i = 1; i <= this.initDataLength; i++) {
      chartData.push(['test' + i, undefined, undefined]);
    }
  }

  addItemAndRemoveOverflow(
    chartData: KpiChartDataItem[],
    itemToAdd: KpiChartDataItem
  ) {
    if (chartData.length > this.maximumLength) {
      chartData.shift();
    }
    chartData.push(itemToAdd);
    if (this.initNumberCount < this.initDataLength) {
      chartData.shift();
    }
  }

  //refresh the RealTimeKPILastestData
  refreshLastestData() {
    this.allRealTimeKPILastestData = {
      payload: this.payloadChartData.at(-1),
      oilTemperature: this.oilTemperatureChartData.at(-1),
      hydraulicPressure: this.hydraulicPressureChartData.at(-1),
      filling: this.fillingChartData.at(-1),
      oilLevel: this.oilLevelChartData.at(-1),
      oilFilterClogged: this.oilFilterCloggedChartData.at(-1),
    };
  }

  //trigger all the tooltips of the charts
  getNewXData(val: string) {
    for (const key of this.kpiKeys) {
      const triggerTooltip = this.$refs[key]?.triggerTooltip;
      triggerTooltip?.(val);
    }
  }

  //hide all the tooltips of the charts
  hideAllTooltip() {
    for (const key of this.kpiKeys) {
      const hideTooltip = this.$refs[key]?.hideTooltip;
      hideTooltip?.();
    }
  }

  //handle the data format before exporting to csv file
  generateCsvJson() {
    let result: CsvJsonEntry[] = [];
    let timeStamp: string = `${this.$t('KPI.timeStamp')}`;
    let currentPayload: string = `${this.$t('KPI.CurrentPayload')}(${this.$t(
      'metricUnits.ton'
    )})`;
    let compactorFillingPercentage: string = `${this.$t(
      'KPI.CompactorFillingPercentage'
    )}(${this.$t('metricUnits.percentage')})`;
    let oilTemperature: string = `${this.$t('KPI.OilTemperature')}(${this.$t(
      'metricUnits.celsius'
    )})`;
    let hydraulicPressure: string = `${this.$t(
      'KPI.HydraulicPressure'
    )}(${this.$t('metricUnits.bar')})`;
    let oilLevel: string = `${this.$t('KPI.OilLevel')}`;
    let oilFilter: string = `${this.$t('KPI.OilFilterClogged')}`;
    let handleDataStartPosition = this.initDataLength - this.initNumberCount;
    for (
      let i = handleDataStartPosition;
      i < this.payloadChartData.length;
      i++
    ) {
      const entry: CsvJsonEntry = {};
      entry[timeStamp] = this.payloadChartData[i][0];
      entry[currentPayload] = this.payloadChartData[i][1]?.toString();
      entry[compactorFillingPercentage] =
        this.fillingChartData[i][1]?.toString();
      entry[oilTemperature] = this.oilTemperatureChartData[i][1]?.toString();
      entry[hydraulicPressure] = this.hydraulicPressureChartData[i][1];
      entry[oilLevel] = oilLevelValueToTranslatedName(
        Number(this.oilLevelChartData[i][1])
      );
      entry[oilFilter] = booleanStateToTranslatedName(
        Number(this.oilFilterCloggedChartData[i][1])
      );
      result.push(entry);
    }
    return result;
  }

  //export to csv file
  exportCsvFile() {
    let toCsvDataArray = this.generateCsvJson();
    const csvData = Papa.unparse(toCsvDataArray);

    let content = new Blob([csvData]);
    let urlObject = window.URL || window.webkitURL || window;
    let url = urlObject.createObjectURL(content);

    const timestamp = moment(new Date()).format(FILENAME_TIMESTAMP_FORMAT);

    let el = document.createElement('a');
    el.href = url;
    el.download = `${this.companyAssetId}_${this.$t(
      'liveMonitoring.liveMonitorFilenameInfix'
    )}_${timestamp}.csv`;
    el.click();
    urlObject.revokeObjectURL(url);
  }
}

interface CsvJsonEntry {
  [name: string]: number | string | undefined;
}
</script>

<template>
  <div>
    <div v-for="(metricConfig, index) in applicableRealTimeKpis" :key="index">
      <!-- current payloads chart -->
      <el-row v-if="metricConfig.code === 'KPI.CurrentPayload'" class="kpi-box">
        <el-col :span="3">
          <p class="kpi-name">{{ $t('KPI.CurrentPayload') }}</p>
          <p
            class="kpi-value"
            :style="{
              color: realTimeKPIColor(allRealTimeKPILastestData.payload),
            }"
          >
            <span>{{ realTimeKPIValues.payload }}</span>
            {{ $t(payloadUnit) }}
          </p>
        </el-col>
        <el-col :span="21">
          <LineChart
            ref="payloadKpi"
            height="150px"
            :limitWarningRange="payloadLimitWarningRange"
            :chartData="payloadChartData"
            :yAxisUnit="$t(payloadUnit)"
            :isRendering="isRendering"
            @send-xdata="getNewXData"
            @hide-tooltip="hideAllTooltip"
          />
        </el-col>
      </el-row>

      <!-- Compactor Filling Percentage Chart  -->
      <el-row
        v-if="metricConfig.code === realTimeKpiCodes.CompactorFillingPercentage"
        class="kpi-box"
      >
        <el-col :span="3">
          <p class="kpi-name">
            {{ $t(realTimeKpiCodes.CompactorFillingPercentage) }}
          </p>
          <p
            class="kpi-value"
            :style="{
              color: realTimeKPIColor(allRealTimeKPILastestData.filling),
            }"
          >
            <span>{{ realTimeKPIValues.filling }}</span>
            {{ $t(fillingUnit) }}
          </p>
        </el-col>
        <el-col :span="21">
          <line-chart
            ref="fillingKpi"
            height="150px"
            :limitWarningRange="fillingLimitWarningRange"
            :chartData="fillingChartData"
            :yAxisUnit="$t('metricUnits.percentage')"
            :isPercentage="true"
            :isRendering="isRendering"
            @send-xdata="getNewXData"
            @hide-tooltip="hideAllTooltip"
          ></line-chart>
        </el-col>
      </el-row>

      <!-- oil temperature chart -->
      <el-row
        v-if="metricConfig.code === realTimeKpiCodes.OilTemperature"
        class="kpi-box"
      >
        <el-col :span="3">
          <p class="kpi-name">{{ $t(realTimeKpiCodes.OilTemperature) }}</p>
          <p
            class="kpi-value"
            :style="{
              color: realTimeKPIColor(allRealTimeKPILastestData.oilTemperature),
            }"
          >
            <span>{{ realTimeKPIValues.oilTemperature }}</span>
            {{ $t(oilTemperatureUnit) }}
          </p>
        </el-col>
        <el-col :span="21">
          <line-chart
            ref="oilTemperatureKpi"
            height="150px"
            :limitWarningRange="oilTemperatureLimitWarningRange"
            :chartData="oilTemperatureChartData"
            :yAxisUnit="$t('metricUnits.celsius')"
            :isRendering="isRendering"
            @send-xdata="getNewXData"
            @hide-tooltip="hideAllTooltip"
          ></line-chart>
        </el-col>
      </el-row>

      <!-- Hydraulic Pressure chart -->
      <el-row
        v-if="metricConfig.code === realTimeKpiCodes.HydraulicPressure"
        class="kpi-box"
      >
        <el-col :span="3">
          <p class="kpi-name">{{ $t(realTimeKpiCodes.HydraulicPressure) }}</p>
          <p
            class="kpi-value"
            :style="{
              color: realTimeKPIColor(
                allRealTimeKPILastestData.hydraulicPressure
              ),
            }"
          >
            <span>{{ realTimeKPIValues.hydraulicPressure }}</span>
            {{ $t(hydraulicPressureUnit) }}
          </p>
        </el-col>
        <el-col :span="21">
          <line-chart
            ref="hydraulicPressureKpi"
            height="150px"
            :limitWarningRange="hydraulicPressureLimitWarningRange"
            :chartData="hydraulicPressureChartData"
            :yAxisUnit="$t('metricUnits.bar')"
            :isRendering="isRendering"
            @send-xdata="getNewXData"
            @hide-tooltip="hideAllTooltip"
          ></line-chart>
        </el-col>
      </el-row>

      <!-- oil level chart  -->
      <el-row
        v-if="metricConfig.code === realTimeKpiCodes.OilLevelLow"
        class="kpi-box"
      >
        <el-col :span="3">
          <p class="kpi-name">
            {{ $t('KPI.OilLevel') /* TODO Fix the key, it's missing 'Low' */ }}
          </p>
          <p
            class="kpi-value"
            :style="{
              color: realTimeKPIColor(allRealTimeKPILastestData.oilLevel),
            }"
          >
            <span>{{ realTimeKPIValues.oilLevel }}</span>
          </p>
        </el-col>
        <el-col :span="21">
          <line-chart
            ref="oilLevelKpi"
            height="150px"
            :limitWarningRange="oilLevelLimitWarningRange"
            :chartData="oilLevelChartData"
            :isOilLevel="true"
            :isRendering="isRendering"
            @send-xdata="getNewXData"
            @hide-tooltip="hideAllTooltip"
          ></line-chart>
        </el-col>
      </el-row>

      <!-- oil filter clogged chart  -->
      <el-row
        v-if="metricConfig.code === realTimeKpiCodes.OilFilterClogged"
        class="kpi-box"
      >
        <el-col :span="3">
          <p class="kpi-name">
            {{ $t(realTimeKpiCodes.OilFilterClogged) }}
          </p>
          <p
            class="kpi-value"
            :style="{
              color: realTimeKPIColor(
                allRealTimeKPILastestData.oilFilterClogged
              ),
            }"
          >
            <span>{{ realTimeKPIValues.oilFilter }}</span>
          </p>
        </el-col>
        <el-col :span="21">
          <line-chart
            ref="oilFilterKpi"
            height="150px"
            :limitWarningRange="oilFilterWarningRange"
            :chartData="oilFilterCloggedChartData"
            :isFlag="true"
            :isRendering="isRendering"
            @send-xdata="getNewXData"
            @hide-tooltip="hideAllTooltip"
          ></line-chart>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.kpi-box {
  border-top: 1px solid #dddddd;
  position: relative;
  padding: 0 10px;
  .kpi-name {
    margin: 14px 0 0 0;
    font-size: 16px;
  }
  .kpi-value {
    font-size: 22px;
    font-weight: bold;
  }
}
</style>
