import { ActiveContext, useActiveContext } from '@/auth/context';
import { LoggedInUser, useLoggedInUser } from '@/auth/user';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { NO_BACKGROUND_FETCH_OPTIONS } from '@/query/common';
import { AssetType } from '@/utils/assetTypes';
import { MaybeRef, MaybeRefDeep } from '@/utils/ref';
import { useQuery, UseQueryReturnType } from '@tanstack/vue-query';
import { computed, unref } from 'vue';
import {
  AssetObjectModel,
  fetchAssetsByFlexibleFiltering,
  fetchOrganizationsAssetsHierarchy,
  OrganizationAssetsHierarchyOrganization,
} from '../api/assets';

const queryKeys = {
  organizationAssetsHierarchyAll: () =>
    ({ query: 'organization-assets-hierarchy' } as const),

  organizationAssetsHierarchyAssetType: (
    params?: MaybeRefDeep<GetOrganizationsAssetsHierarchyRawParams>
  ) =>
    ({
      ...queryKeys.organizationAssetsHierarchyAll(),
      scope: 'all',
      params,
    } as const),

  allAssets: (
    assetType: MaybeRef<AssetType | undefined>,
    enabled?: MaybeRef<boolean>
  ) => ({ query: 'all-assets', assetType, enabled } as const),
};

export function useAllAssetsQuery(
  assetType?: MaybeRef<AssetType | undefined>,
  enabled?: MaybeRef<boolean>
): UseQueryReturnType<AssetObjectModel[], Error> {
  return useQuery({
    ...NO_BACKGROUND_FETCH_OPTIONS,
    enabled,
    queryKey: [queryKeys.allAssets(assetType, enabled)],
    queryFn: async ({ queryKey: [{ assetType }] }) => {
      const result = await fetchAssetsByFlexibleFiltering(
        !assetType
          ? {}
          : {
              filters: [
                {
                  name: 'assetType',
                  operator: FilterOperator.EQUAL,
                  value: [assetType],
                },
              ],
            }
      );

      return result.data.assets;
    },
  });
}

/**
 * Fetch assets hierarchy for currently selected organization/customer.
 *
 * Optionally retrieve only assets of a certain asset type.
 */
export function useOrganizationAssetsHierarchyQuery(
  assetType?: MaybeRef<AssetType | undefined>,
  enabled?: MaybeRef<boolean | undefined>
): UseQueryReturnType<OrganizationAssetsHierarchyOrganization, Error> {
  const context = useActiveContext();
  const loggedInUser = useLoggedInUser();

  return useOrganizationAssetsHierarchyQueryRaw(
    computed(() => ({
      assetType: unref(assetType),
      organizationId: unref(context).organization?.id,
      context: unref(context),
      enabled: unref(enabled),
      loggedInUser: unref(loggedInUser),
    }))
  );
}

export interface GetOrganizationsAssetsHierarchyRawParams {
  assetType?: AssetType;
  organizationId?: string;
  context?: ActiveContext;
  loggedInUser?: LoggedInUser;
  enabled?: boolean;
}

/**
 * Fetch assets hierarchy for specific organization/customer.
 *
 * Optionally retrieve only assets of a certain asset type.
 *
 * NOTE: You should probably use `useOrganizationAssetsHierarchyQuery()` instead.
 */
export function useOrganizationAssetsHierarchyQueryRaw(
  params?: MaybeRefDeep<GetOrganizationsAssetsHierarchyRawParams>
): UseQueryReturnType<OrganizationAssetsHierarchyOrganization, Error> {
  return useQuery({
    staleTime: 60 * 1000,
    enabled: computed(() => unref(params)?.enabled !== false),
    queryKey: [queryKeys.organizationAssetsHierarchyAssetType(params)],
    queryFn: ({ queryKey: [{ params }] }) =>
      fetchOrganizationsAssetsHierarchy(
        params?.assetType,
        params?.organizationId,
        params?.context as ActiveContext
      ),
  });
}
