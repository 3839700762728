import { CommonResultPromise } from '@/api/commonResult';
import { GwValue, Value } from '@/api/value';
import { ActiveContext } from '@/auth/context';
import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { api } from '@/utils/api';
import { getRequest } from '@/utils/request';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { UTCTimestamp, UUID } from './common';

export enum PropertyType {
  PRPTYP_CALIBRATION = 'PRPTYP_CALIBRATION',

  /**
   * Properties defined (get a value) at installation / calibration time.
   * Can change during normal operation also, but triggered by external (manual) action. E.g. change a certain check interval.
   */
  PRPTYP_CONFIGURATION = 'PRPTYP_CONFIGURATION',

  /**
   * Properties for which values are generated at usage of an asset, mostly time series based.
   * Can be values measured by devices, or calculated values or system/software state values.
   */
  PRPTYP_TELEMETRY = 'PRPTYP_TELEMETRY',

  /**
   * Property represents an event that can be received from the gateway.
   * An event can indicate a safety problem, or other alarm condition.
   */
  PRPTYP_EVENT = 'PRPTYP_EVENT',

  /**
   * Properties defined (get a value) at product  catalogue definition time.
   */
  PRPTYP_SPECIFICATION = 'PRPTYP_SPECIFICATION',

  /**
   * Properties defined (get a value) at manufacturing time.
   */
  PRPTYP_MANUFACTURING = 'PRPTYP_MANUFACTURING',

  /**
   * Properties for which values are derived as summary of an operation. What actually is defined as an operation will differ per AssetType.
   * Can be min/max values during an operation, counters of number of operation, number of warnings, statistical info like total load handled.
   * Values are stored on a per operation basis.
   */
  PRPTYP_OPERATION = 'PRPTYP_OPERATION',

  /**
   * Property contains safety related event. Like Overload event for Tipping.
   */
  PRPTYP_UEC = 'PRPTYP_UEC',

  PRPTYP_OTHER = 'PRPTYP_OTHER',

  PRPTYP_SAFETY = 'PRPTYP_SAFETY',

  /**
   * Property indicates an error condition in the equipment.  (Like Oil Level low for Mobile Compactor)
   */
  PRPTYP_ERROR = 'PRPTYP_ERROR',

  PRPTYP_KPI = 'PRPTYP_KPI',

  PRPTYP_DESCRIPTIVE = 'PRPTYP_DESCRIPTIVE',

  PRPTYP_AGGREGATION_DELTA = 'PRPTYP_AGGREGATION_DELTA',

  PRPTYP_SYSTEM_CONFIG = 'PRPTYP_SYSTEM_CONFIG',
}

/**
 * Configuration parameter value configured on cloud side,
 * as communicated on REST API endpoints towards FE.
 */
export interface CloudConfiguredParameterValue<T> {
  v: T;

  /**
   * Configured timestamp (ISO8601 UTC).
   *
   * Updates whenever value is explicitly (re-)configured, regardless of
   * whether the value stayed the same compared to previous attempt.
   *
   * Value should be strictly monotonic (per parameter).
   *
   * Corresponds to {@link GWConfiguredParameterValue.uts}.
   */
  configuredTimestamp: UTCTimestamp;

  /**
   * Snapshot of in-use value at the time this configured value
   * was stored.
   */
  inUseValueSnapshot?: Value<T>;
}

/**
 * Report of current in-use value in GW, as sent by GW.
 *
 * @see {@link DerivedInuseParameterValue} for how the value is stored
 * in TB's telemetry database.
 */
export interface GWInuseParameterValue<T> extends GwValue<T> {
  /**
   * Echoed timestamp.
   *
   * Copied from last-seen ConfiguredParameterValue's `uts`, if any.
   *
   * If not given, cloud will assume gateway never saw any ConfiguredParameterValue
   * for this parameter yet.
   */
  ets?: UTCTimestamp;

  /**
   * Update timestamp.
   *
   * Timestamp at which value was last explicitly (re-)updated (even though it
   * may have stayed the same).
   *
   * If the value changed, `cts` and `uts` will be equal, if it didn't change,
   * `uts` will be newer than `cts`.
   * Sensed timestamp (i.e. meta ts) should always be same or newer.
   *
   * If not given, cloud will derive `uts` based on changes to InuseParameterValue's other
   * fields (`v`, `q`, and `ets`, etc.), and will take Sense timestamp (meta ts) in that case.
   */
  uts?: UTCTimestamp;

  /**
   * Change timestamp.
   *
   * Timestamp at which value has changed.
   *
   * If the value changed, `cts` and `uts` will be equal, if it didn't change,
   * `uts` will be newer than `cts`.
   * Sensed timestamp (i.e. meta ts) should always be same or newer.
   *
   * If not given, cloud will derive `cts` based on changes to `v` and `q`, and
   * will take (derived) `uts` in that case.
   */
  cts?: UTCTimestamp;

  /**
   * Previous value (`v` and `q`) of InuseParameterValue, at last moment of change.
   *
   * If not given, cloud will derive it from previously received InuseParameterValue.
   */
  pv?: GwValue<T>;
}

/**
 * In-use value in GW as reported by GW, with any optional fields filled
 * in on cloud side if necessary.
 *
 * Specifically, `uts`, `cts` and `pv` are optional on the GW->Cloud interface,
 * and will be filled in by rulechains based on previous GW telemetry if missing.
 */
export interface DerivedInuseParameterValue<T>
  extends GWInuseParameterValue<T> {
  /**
   * Update timestamp.
   *
   * If not given by GW, it is set to meta ts if a change in InuseParameterValue
   * is detected (`v`, `q`, `ets`, etc.) or if this is the first time a value is received.
   */
  uts: UTCTimestamp;

  /**
   * Change timestamp.
   *
   * If not given by GW, it is set to (derived) `uts` if a change in `v` and/or `q`
   * is detected, or if this is the first time a value is received.
   */
  cts: UTCTimestamp;

  /**
   * Published timestamp.
   *
   * Timestamp on cloud at receiving of InuseParameterValue.
   * Should always be newer than any of the other timestamps (including meta ts).
   */
  // pts: UTCTimestamp;

  /**
   * Previous value (`v` and `q`) of InuseParameterValue, at last moment of change.
   *
   * If not given, cloud will derive it from previously received InuseParameterValue,
   * or leave as `undefined` if this is the first value received.
   */
  pv?: GwValue<T>;
}

// API ENDPOINTS

/**
 * Response of `api/v1.0/configuration-parameters/current/{assetUUID}` endpoint.
 */
export interface GetCurrentAssetConfigurationParametersResponse {
  assetTimezone: string;
  configurationParameters: CurrentAssetConfigurationParameter<any>[];
}

export interface CurrentAssetConfigurationParameter<T> {
  /**
   * Configuration parameter's property code.
   *
   * `PRPTYP_CONFIGURATION` properties will always start with `CPARM.`.
   */
  propertyCode: string;

  /**
   * (Translated) name of configuration parameter.
   */
  propertyName: string;

  /**
   * Property type.
   *
   * E.g. `PRPTYP_CONFIGURATION` or `PRPTYP_SYSTEM_CONFIG`.
   */
  propertyType: PropertyType;

  /**
   * Unit code of configuration parameter.
   *
   * E.g. `UNIT_RPM`.
   */
  unitCode: KPI_UNIT;

  /**
   * Whether property is (technically) writable.
   *
   * Additional measures may be verified, such as a user's role.
   * This field mainly determines whether the GW would accept cloud-
   * configured values.
   */
  isWritable: boolean;

  /**
   * Latest in-use value in GW, if any.
   */
  inUseValue?: DerivedInuseParameterValue<T>;

  /**
   * Latest configured value in cloud, if any.
   */
  configuredValue?: CloudConfiguredParameterValue<T>;
}

/**
 * Request body of `api/v1.0/configuration-parameters/change-set/{assetUUID}` endpoint.
 */
export interface PostAssetConfigurationChangeSetRequest {
  configurationParameters: ConfigurationParameterSetpointValue<any>[];
}

/**
 * Response body of `api/v1.0/configuration-parameters/change-set/{assetUUID}` endpoint.
 */
export interface PostAssetConfigurationChangeSetResponse {
  changeSetUuid: UUID;
  configuredTimestamp: UTCTimestamp;
  configurationParameters: ConfigurationParameterSetpointValue<any>[];
}

export interface ConfigurationParameterSetpointValue<T> {
  propertyCode: string;
  value: T;
}

export interface PostAssetConfigurationVpnResponse {
  vpnEnableTimestamp: string;
}

/**
 * Fetch configuration parameters by single asset id and request body query parameters
 * @param assetUUID
 * @param requestBody
 * @param context
 * @param request
 * @returns
 */
export function getCurrentAssetConfigurationParameters(
  assetUUID: string,
  requestBody: QueryParameter,
  context?: ActiveContext,
  request = getRequest()
): CommonResultPromise<GetCurrentAssetConfigurationParametersResponse> {
  return request.post<GetCurrentAssetConfigurationParametersResponse>(
    `/configuration-parameters/current/${assetUUID}`,
    requestBody,
    {
      params: {
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );
}

/**
 * Update config parameters by single asset id
 * @param assetUUID
 * @param body
 * @param context
 * @param request
 * @returns
 */
export function postAssetConfigurationChangeSet(
  assetUUID: UUID,
  body: PostAssetConfigurationChangeSetRequest,
  context?: ActiveContext,
  request = getRequest()
): CommonResultPromise<PostAssetConfigurationChangeSetResponse> {
  return request.post(
    `/configuration-parameters/change-set/${assetUUID}`,
    body,
    {
      params: {
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );
}

/**
 * Update configurations for Vpn by single asset id
 * Currently, supports only enabling of Vpn. So, no POST body.
 * @param assetUUID
 */
export async function postAssetConfigurationVpn(
  assetUUID: UUID
): Promise<PostAssetConfigurationVpnResponse> {
  return await api().post(`/configuration-parameters/vpn/${assetUUID}`);
}
