var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-table',{attrs:{"data":_vm.tableData,"height":"100%"}},[_c('el-table-column',{attrs:{"prop":_vm.aggregationType === _setup.AggregationType.AggregateByAsset
        ? 'assetId'
        : 'date',"label":_vm.aggregationType === _setup.AggregationType.AggregateByAsset
        ? _vm.$t('kpiTarget.table.assetId')
        : _vm.$t('UNIT_DAY'),"sortable":""}}),_c('el-table-column',{attrs:{"prop":"trip","label":_vm.$t('kpiTarget.table.tripCount'),"sortable":""}}),_c('el-table-column',{attrs:{"prop":"payload","label":`${_vm.$t('kpiTarget.table.payload')} (${_vm.$t('UNIT_METRIC_TONNE')})`,"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_setup.formatNumber(props.row.payload))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"payloadPerTrip","label":`${_vm.$t('kpiTarget.table.payloadPerTrip')} (${_vm.$t(
      'UNIT_METRIC_TONNE'
    )})`,"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_setup.formatNumber(props.row.payloadPerTrip))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }