<script lang="ts" setup>
import { TranslateResult } from 'vue-i18n';
import { useRouter, useRoute } from '@/composables/router';
import { unref } from 'vue';

const router = useRouter();
const route = useRoute();

const props = defineProps<{
  crumbs: TranslateResult[];
}>();

/**
 * Handle navigation from title bread crumb click event
 * Appliable only for children routes under Assets left side menu
 * @param crumb
 */
function handleChangeRoute(crumb: TranslateResult): void {
  const currentCrumbIndex: number = props.crumbs.indexOf(crumb);
  let goToRouthPath: string = '';

  if (route.value.params && Object.keys(route.value.params).includes('id')) {
    /**
     * Check bread crumb click event
     * - If is on main asset type redirect to route
     * - If is any of the inner path in the single asset sub children
     * navigation to top most level of that single asset route
     */
    if (currentCrumbIndex === 0) {
      const pathSegments: string[] = unref(route).fullPath?.split('/');
      goToRouthPath = '/' + pathSegments[1] + '/' + pathSegments[2];
    } else if (currentCrumbIndex === 1 || currentCrumbIndex === 2) {
      goToRouthPath = `${unref(route).path}?tab=${unref(route).query.tab}`;
    } else {
      return;
    }

    /** Do not allow nagivation on same route path */
    if (unref(route).fullPath === goToRouthPath) return;
    router.push(goToRouthPath);
    return;
  }

  if (currentCrumbIndex === 0 && unref(route).fullPath != unref(route).path) {
    router.push(unref(route).path);
  }
}
</script>

<template>
  <div class="title">
    <span
      v-for="crumb of props.crumbs"
      :key="crumb.toString()"
      class="crumb"
      @click="handleChangeRoute(crumb)"
    >
      {{ crumb }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.title {
  color: #373e41;
  font-size: 1.25rem;
  font-weight: 500;

  & .crumb:not(:last-child) {
    opacity: 0.75;

    &::after {
      content: '»';
      padding: 0 0.75rem;
    }
  }

  & .crumb:hover:not(:last-child) {
    cursor: pointer;
  }
}
</style>
