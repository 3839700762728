import { EventResponse, getEvents } from '@/api/event';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { LoggedInUser, useLoggedInUser } from '@/auth/user';
import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { MaybeRef } from '@/utils/ref';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import {
  keepPreviousData,
  useQuery,
  UseQueryReturnType,
} from '@tanstack/vue-query';
import { computed, unref } from 'vue';
import { GenericQueryOptions } from './common';

const queryKeys = {
  events: (
    queryParameters: QueryParameter,
    context?: ActiveContext,
    loggedInUser?: LoggedInUser
  ) =>
    ({
      query: 'events',
      queryParameters: queryParameters,
      context: context,
      loggedInUser: loggedInUser,
    } as const),
};

/**
 * This query only runs if `unref(queryParameters) !== undefined`
 */
export function useEventsQuery(
  queryParameters: MaybeRef<QueryParameter | undefined>,
  options?: GenericQueryOptions<EventResponse, (typeof queryKeys)['events']>
): UseQueryReturnType<EventResponse, Error> {
  const context = useActiveContext();
  const loggedInUser = useLoggedInUser();

  return useQuery({
    enabled: computed(() => unref(queryParameters) !== undefined),
    ...options,
    placeholderData: keepPreviousData,
    queryKey: [
      computed(() =>
        queryKeys.events(
          unref(queryParameters)!,
          context.value,
          loggedInUser.value
        )
      ),
    ],
    queryFn: ({ queryKey: [{ queryParameters, context, loggedInUser }] }) =>
      getEvents(
        queryParameters!,
        loggedInUser?.companyType !== COMPANY_TYPE.Hyva
          ? (context as ActiveContext)
          : undefined
      ),
  });
}
