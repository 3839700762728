import { PROPER_ASSET_TYPE } from '@/utils/workData/lookuptable';

export const DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS = [
  {
    label: 'deployManagementModule.pendingUpdates.companyAssetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label:
      'deployManagementModule.pendingUpdates.currentProductSystemReleaseId',
    prop: 'currentSystemReleaseId',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.pendingUpdates.newProductSystemReleaseId',
    prop: 'newSystemReleaseId',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.pendingUpdates.organization',
    prop: 'organizationName',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.pendingUpdates.newSystemReleaseVersion',
    prop: 'newSystemReleaseVersion',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.pendingUpdates.assetType',
    prop: 'assetType',
    required: true,
    multiLang: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.pendingUpdates.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.pendingUpdates.modelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.pendingUpdates.lastCommunicationTime',
    prop: 'lastCommunicationTime',
    required: true,
    visible: true,
    dateFormat: 'datetime',
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.pendingUpdates.opreationalStatus',
    prop: 'opreationalStatus',
    required: false,
    multiLang: true,
    visible: true,
    sortable: 'custom',
  },
];

export const PENDING_UPDATES_FILTER_OPTIONS = [
  {
    field: 'companyAssetId',
    label: 'deployManagementModule.pendingUpdates.companyAssetId',
  },
  {
    field: 'organizationName',
    label: 'deployManagementModule.pendingUpdates.organization',
  },
  {
    field: 'currentSystemReleaseId',
    label:
      'deployManagementModule.pendingUpdates.currentProductSystemReleaseId',
  },
  {
    field: 'newSystemReleaseId',
    label: 'deployManagementModule.pendingUpdates.newProductSystemReleaseId',
  },
  {
    field: 'newSystemReleaseVersion',
    label: 'deployManagementModule.pendingUpdates.newSystemReleaseVersion',
  },
  {
    field: 'assetType',
    label: 'deployManagementModule.pendingUpdates.assetType',
    dropdownEnum: PROPER_ASSET_TYPE,
  },
  {
    field: 'productModelCode',
    label: 'deployManagementModule.pendingUpdates.productModel',
  },
  {
    field: 'productModelNumber',
    label: 'deployManagementModule.pendingUpdates.modelNumber',
  },
  {
    field: 'opreationalStatus',
    label: 'deployManagementModule.pendingUpdates.opreationalStatus',
  },
];
