<script lang="ts" setup>
import {
  getCurrentAssetConfigurationParameters,
  postAssetConfigurationChangeSet,
} from '@/api/configParams';
import { getKpisForMultipleAssets } from '@/api/kpis';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { ButtonProps } from '@/components/button/CardButton.vue';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import { useAsync } from '@/composables/async';
import { useUnitConversion } from '@/composables/conversion';
import { showNotificationOnError } from '@/composables/error';
import { useRoute, useRouter } from '@/composables/router';
import { useAccessControlSystemFeature } from '@/composables/systemFeature';
import i18n from '@/lang';
import {
  FilterOperator,
  Pagination,
  QueryParameter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import {
  RouteNames,
  routeNameToAssetTypeMappping,
} from '@/router/modules/assets';
import { UserModule } from '@/store/modules/user';
import { AssetType } from '@/utils/assetTypes';
import { isCompanyTypeOf } from '@/utils/companyService';
import { formatValue, UndefinedRendering } from '@/utils/format';
import { formatTimer } from '@/utils/misc';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { convertRawNumber } from '@/utils/units/conversion';
import {
  ALL_CLAIMS_CODES,
  COMPANY_TYPE,
  FvtAccessControlOption,
  KPI_FIELDS,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import ParameterConfigurationTable, {
  configureColumns,
  mainPageColumns,
  ParametersTableRow,
} from '@/views/parametersConfiguration/ParameterConfigurationTable.vue';
import { computed, reactive, ref, triggerRef, unref } from 'vue';

const route = useRoute();
const context = useActiveContext();

const authMap: Map<ALL_CLAIMS_CODES, ALL_CLAIMS_CODES> = new Map([
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_PARAMS_CONFIG,
    ALL_CLAIMS_CODES.AUTHRSC_ACTION_TIPPING_VEHICLES_UPDATE_PARAMS,
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_PARAMS_CONFIG,
    ALL_CLAIMS_CODES.AUTHRSC_ACTION_SCOMPACTOR_UPDATE_PARAMS,
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_PARAMS_CONFIG,
    ALL_CLAIMS_CODES.AUTHRSC_ACTION_MCOMPACTOR_UPDATE_PARAMS,
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_PARAMS_CONFIG,
    ALL_CLAIMS_CODES.AUTHRSC_ACTION_ALBA_SCOMPACTOR_UPDATE_PARAMS,
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_PARAMS_CONFIG,
    ALL_CLAIMS_CODES.AUTHRSC_ACTION_RCV_UPDATE_PARAMS,
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_GENERIC_ASSET_PARAMS_CONFIG,
    ALL_CLAIMS_CODES.AUTHRSC_ACTION_GENERIC_ASSET_UPDATE_PARAMS,
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_OPER_TIPPING_VEHICLES_PARAMS_CONFIG,
    ALL_CLAIMS_CODES.AUTHRSC_ACTION_OPER_TIPPING_VEHICLES_UPDATE_PARAMS,
  ],
]);

const router = useRouter();

const hasParametersAccess = computed((): boolean => {
  const tab = router.currentRoute.query.tab as ALL_CLAIMS_CODES;
  const neededClaim = authMap.get(tab);
  return neededClaim !== undefined && UserModule.claims.hasClaim(neededClaim);
});

const saving = ref(false);

const assetType = computed((): AssetType | undefined => {
  const routeName = route.value.name as RouteNames;
  return routeNameToAssetTypeMappping[routeName];
});

const parameterConfigurationEntitlement = useAccessControlSystemFeature(
  SYSTEM_FEATURES.ParameterConfig,
  assetType
);

const buttons = computed((): ButtonProps[] => {
  if (mode.value === Mode.View) {
    if (hasParametersAccess.value === true) {
      const buttons = [
        {
          title: i18n.tc('paramConfig.refresh'),
          onClick: forceRefresh,
          disabled: unref(currentAssetConfigParams).loading,
        },
      ];

      if (
        parameterConfigurationEntitlement.value ===
        FvtAccessControlOption.ReadWrite
      ) {
        buttons.push({
          title: i18n.tc('paramConfig.configure'),
          onClick: handleConfigure,
          disabled: false,
        });
      }

      return buttons;
    }

    return [];
  }

  return [
    {
      title: i18n.tc('paramConfig.cancel'),
      onClick: handleCancel,
      disabled: unref(saving),
    },
    {
      title: i18n.tc('paramConfig.apply'),
      onClick: handleApply,
      disabled:
        unref(saving) ||
        unref(editModeRows).filter((row) => !!row.newValue).length === 0,
    },
  ];
});

function handleConfigure(): void {
  enterEditMode();
}

function handleCancel(): void {
  enterViewMode();
}

function handleApply(): void {
  applyEditedParameters();
}

enum Mode {
  View,
  Edit,
}

const mode = ref<Mode>(Mode.View);
const columns = computed(() =>
  unref(mode) === Mode.Edit ? configureColumns : mainPageColumns
);

const { currentUserPreferredUnit } = useUnitConversion();

async function fetchCurrentConfigParams(
  assetUUID: string,
  requestBody: QueryParameter,
  context?: ActiveContext
): Promise<ParametersTableRow<any>[]> {
  const response = await getCurrentAssetConfigurationParameters(
    assetUUID,
    requestBody,
    context
  );

  return response.data.configurationParameters.map(
    (param): ParametersTableRow => {
      const userUnit = currentUserPreferredUnit(param.unitCode);

      return {
        propertyCode: param.propertyCode,
        propertyName: param.propertyName,
        metricUnit: param.unitCode,
        unitCode: userUnit,
        inUseValue: param.inUseValue?.v
          ? formatValue(
              {
                v: convertRawNumber(
                  Number(param.inUseValue?.v),
                  param.unitCode,
                  userUnit
                ),
              },
              {
                undefinedAs: UndefinedRendering.Empty,
                maxDecimals: 4,
              }
            )
          : undefined,
        configuredValue: param?.configuredValue?.v
          ? formatValue(
              {
                v: convertRawNumber(
                  Number(param?.configuredValue?.v),
                  param.unitCode,
                  userUnit
                ),
              },
              {
                undefinedAs: UndefinedRendering.Empty,
                maxDecimals: 4,
              }
            )
          : undefined,
        lastChangedTime: param.inUseValue?.cts!,
        lastConfiguredTime: param?.configuredValue?.configuredTimestamp!,
        isWritable: param.isWritable,
        newValue: undefined,
        hasValidationError: false,
      };
    }
  );
}

const triggerFetch = ref();
function forceRefresh(): void {
  triggerRef(triggerFetch);
}

/**
 * Prepare query parameters to fetch available maintenance assets for current selected org
 */
function getConfigParamsQueryParameters(): QueryParameter {
  const pagination: Pagination = { page: 1, size: 100000 };
  const i18nCode = UserModule.i18nCode;
  const selectedOrganization = unref(context.value).organization;
  const queryParameters: QueryParameter = {
    filters: !i18nCode
      ? []
      : [
          {
            name: 'i18nCode',
            operator: FilterOperator.EQUAL,
            value: [i18nCode],
          },
        ],
    sorters: [
      {
        field: 'propertyName',
        order: SorterOrder.ASC,
      },
    ],
    pagination: pagination,
    timezone: selectedOrganization?.timezone,
  };
  return queryParameters;
}

/**
 * Fetch from API available assets list
 */
const currentAssetConfigParams = useAsync(
  computed(() => {
    unref(triggerFetch);
    return fetchCurrentConfigParams(
      router.currentRoute.params.id,
      getConfigParamsQueryParameters(),
      unref(context)
    );
  })
);

showNotificationOnError(
  currentAssetConfigParams,
  'paramConfig.errors.loadingFailed'
);

const editModeRows = ref<ParametersTableRow[]>([]);

function enterViewMode() {
  mode.value = Mode.View;
}

function enterEditMode() {
  mode.value = Mode.Edit;
  editModeRows.value = reactive(
    JSON.parse(JSON.stringify(unref(currentAssetConfigParams).data ?? []))
  );
}

async function applyEditedParameters() {
  const editedValues = unref(editModeRows).filter((row) => !!row.newValue);
  const haveErrors = editedValues.some((row) => row.hasValidationError);
  if (haveErrors) {
    customFailedMessage(i18n.tc('paramConfig.errors.invalidValues'));
    return;
  }
  try {
    saving.value = true;
    const response = await postAssetConfigurationChangeSet(
      router.currentRoute.params.id,
      {
        configurationParameters: editedValues.map((row) => ({
          propertyCode: row.propertyCode,
          value: convertRawNumber(
            parseFloat(row.newValue),
            row.unitCode,
            row.metricUnit
          ),
        })),
      },
      context.value
    );
    if (response.code === 200 || response.code === 201) {
      customSuccessMessage(i18n.tc('paramConfig.successfullyApplied'));
    }
    enterViewMode();
  } catch (err) {
    console.error(err);
    customFailedMessage(i18n.tc('paramConfig.errors.savingFailed'));
  } finally {
    saving.value = false;
    forceRefresh();
  }
}

const pageTitle = computed(() =>
  unref(mode) === Mode.Edit
    ? i18n.t('paramConfig.configureParams')
    : i18n.t(router.currentRoute.query.tab as string)
);

/**
 * Prepare request body for multiple kpis asset for last communication time
 * @param assetId
 */
const requestQueryParamsForKpis = (assetId: string) => ({
  metadata: {
    filter: {
      assetIds: [assetId],
    },
  },
  details: [
    {
      fields: [{ code: KPI_FIELDS.LastCommunicationTime }],
    },
  ],
});

/**
 * Check when the last communication with the GW has happened
 * Available only of hyva helpdesk and body builder bompany type
 */
const lastCommunicationTimeAPIResponse = useAsync(
  computed(async () => {
    unref(triggerFetch);
    const assetId = unref(route).params.id;
    const kpiResponse = await getKpisForMultipleAssets(
      requestQueryParamsForKpis(assetId),
      isCompanyTypeOf([COMPANY_TYPE.Helpdesk]) ? unref(context) : undefined
    );
    return kpiResponse.data;
  })
);

/**
 * Return last communication time formatted
 * by default system pattern with data and time and to logged in user time zone
 */
const lastCommunicationTime = computed(() => {
  const apiResponse = unref(lastCommunicationTimeAPIResponse).data;
  if (!apiResponse) return;
  return formatTimer(apiResponse[0].values[0]?.v!, 'datetime');
});

/**
 * Allow last communication time field and value to be visible only for BB/Helpdesk company types
 */
const lastCommunicationTimeCanBeDisplayed = computed(() => {
  return isCompanyTypeOf([COMPANY_TYPE.BodyBuilder, COMPANY_TYPE.Helpdesk]);
});
</script>

<template>
  <BaseCard
    class="parameter-configuration-main-container"
    :title="pageTitle"
    :backIconVisible="mode === Mode.Edit"
    :buttons="buttons"
    :showDialogBeforeBack="true"
    @handle-back="handleCancel"
  >
    <div class="table-container">
      <div
        class="communication-time-label"
        v-if="lastCommunicationTimeCanBeDisplayed"
        v-loading="lastCommunicationTimeAPIResponse.loading"
      >
        {{ i18n.tc('assets.lastCommunicationTime') + ':' }}
        {{ lastCommunicationTime }}
      </div>

      <ParameterConfigurationTable
        class="parameters-table"
        v-loading="currentAssetConfigParams.loading"
        :tableData="
          mode === Mode.Edit
            ? editModeRows
            : currentAssetConfigParams.data ?? []
        "
        :columns="columns"
        :paginated="false"
        :page-size="1"
        :total="1"
      />
    </div>
  </BaseCard>
</template>

<style lang="scss" scoped>
.parameter-configuration-main-container {
  grid-area: 1 / 1 / span 1 / span 12;
  grid-column-start: 1;
  grid-column-end: span 12;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 66vh;
  overflow: hidden;
}

.table-container {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  .communication-time-label {
    margin: 20px;
    font-family: var(--fontRobotoRegular, Roboto-Regular);
    font-weight: bold;
  }
}

.parameters-table {
  margin-left: 10px;
}
</style>
