import { CommonResult } from '@/api/commonResult';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { stringify } from 'qs';

/**
 * Custom version of Axios instance that return the JSON-decoded response directly, instead of
 * being wrapped in an additional AxiosResponse.
 */
// prettier-ignore
export interface ApiInstance {
  request<Response, Request>(config: AxiosRequestConfig<Request>): Promise<Response>;
  get<Response, Request = never>(url: string, config?: AxiosRequestConfig<Request>): Promise<Response>;
  delete<Response, Request = never>(url: string, config?: AxiosRequestConfig<Request>): Promise<Response>;
  head<Response, Request>(url: string, config?: AxiosRequestConfig<Request>): Promise<Response>;
  options<Response, Request>(url: string, config?: AxiosRequestConfig<Request>): Promise<Response>;
  post<Response, Request>(url: string, data?: Request, config?: AxiosRequestConfig<Request>): Promise<Response>;
  put<Response, Request>(url: string, data?: Request, config?: AxiosRequestConfig<Request>): Promise<Response>;
  patch<Response, Request>(url: string, data?: Request, config?: AxiosRequestConfig<Request>): Promise<Response>;
  postForm<Response, Request>(url: string, data?: Request, config?: AxiosRequestConfig<Request>): Promise<Response>;
  putForm<Response, Request>(url: string, data?: Request, config?: AxiosRequestConfig<Request>): Promise<Response>;
  patchForm<Response, Request>(url: string, data?: Request, config?: AxiosRequestConfig<Request>): Promise<Response>;
}

let apiInstance: ApiInstance | undefined;
let apiToken: string | undefined;

export function setApiToken(token: string | undefined): void {
  apiToken = token;
}

export function initializeApiService(baseUrl: string): void {
  const service = axios.create({
    baseURL: baseUrl,
    paramsSerializer: (params) => stringify(params, { indices: false }),
  });

  // Add token to all outgoing requests
  service.interceptors.request.use((config) => {
    if (apiToken) {
      config.headers!['Authorization'] = 'Bearer ' + apiToken;
    }
    return config;
  });

  // Convert CommonResult back to 'normal' result
  service.interceptors.response.use(
    (axiosResponse: AxiosResponse<CommonResult<any>>) => {
      // Application backend always returns data wrapped in a CommonResult structure.
      // The .code should always match the HTTP status code, and if that isn't 2xx, it
      // will take the error path. But let's just be safe, and double-check here.
      const res = axiosResponse.data;
      if (!(res.code >= 200 && res.code < 300)) {
        throw new Error(
          `unexpected server response, expected code 2xx, received ${res.code}: '${res.message}'`
        );
      }

      // Return just the (JSON-)parsed data, not the Axios wrapper
      return res.data;
    }
  );

  apiInstance = service;
}

export function api(): ApiInstance {
  if (!apiInstance) throw new Error('API service is not initialized');
  return apiInstance;
}
